import React from "react";

export default function TextInput({
  label,
  value,
  placeholder,
  error,
  type,
  onChange,
  width,
  height,
}) {
  return (
    <div>
      {label && (
        <div>
          <label>{label}</label>
        </div>
      )}
      <div>
        <input
          type={type}
          value={value}
          placeholder={placeholder}
          onChange={(e) => onChange(e.target.value)}
          style={{
            height: height ? height : 30,
            width: width ? width : 200,
            borderColor: error ? "red" : "white",
            paddingLeft: 6,
            borderWidth: error ? 1 : 0,
          }}
        />
      </div>
      {error && (
        <div style={{ fontSize: 12, color: "red", paddingTop: 4 }}>{error}</div>
      )}
    </div>
  );
}
