import axios from "axios";
import {
  GET_ERRORS,
  SET_PEDAGOGICAL_AREAS,
  SET_PEDAGOGY_LOADING,
} from "./types";

export const getPedagogicalAreas = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .get("/pedagogy/pedagogicalareas")
      .then((res) => {
        dispatch(setPedagogicalAreas(res.data));
        resolve(res.data);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response,
        });
        reject(err);
      });
  });
};

//Set loged in user
export const setPedagogicalAreas = (data) => {
  return {
    type: SET_PEDAGOGICAL_AREAS,
    payload: data,
  };
};

export const savePedagogicalArea = (payload, parentId, subject) => (
  dispatch
) => {
  return new Promise((resolve, reject) => {
    //console.log(payload, parentId, subject);
    dispatch({
      type: SET_PEDAGOGY_LOADING,
    });
    const id = !!parentId ? `/${parentId}` : "";
    let uri;

    if ("centralcontent" in payload || subject === "pedagogicalareas") {
      // if pedagogical area
      uri = "pedagogy/pedagogicalarea";
      delete payload.centralcontent;
    } else if ("milestones" in payload || subject === "centralcontent") {
      // if central content
      uri = `pedagogy/pedagogicalarea${id}/centralcontent`;
      delete payload.milestones;
    } else if ("activities" in payload || subject === "milestone") {
      // if milestone
      uri = `pedagogy/centralcontent${id}/milestone`;
      delete payload.activities;
    } else {
      uri = "pedagogy/pedagogicalarea";
    }

    axios
      .post(uri, payload)
      .then((res) => {
        dispatch(getPedagogicalAreas());
        resolve(res.data);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        dispatch(getPedagogicalAreas());
        reject(err.response.data);
      });
  });
};
