import React from "react";
import {
  Radar,
  RadarChart,
  PolarGrid,
  Legend,
  PolarAngleAxis,
  PolarRadiusAxis,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Text,
} from "recharts";

const randomColor = () =>
  ("#" + ((Math.random() * 0xffffff) << 0).toString(16) + "000000").slice(0, 7);

const Polar = (props) => {
  let myData = [];

  const sections = props.sections;

  let max = props.max;

  props.data.centralcontents.map((centralcontent) => {
    centralcontent.milestones.map((milestone) => {
      if (!milestone.shortName) {
        console.log("missing name: ", milestone.name);
      }
      let dataItem = {};
      dataItem.name = milestone.name;
      dataItem.shortName = milestone.shortName
        ? milestone.shortName
        : milestone.name;
      milestone.count.map((count) => {
        dataItem[count._id] = count.count + 1;
        // max = count.count > max ? count.count : max;
      });
      myData.push(dataItem);
    });
  });

  const radars = sections.map((section) => {
    const color = randomColor();
    return (
      <Radar
        name={section.name}
        dataKey={section._id}
        stroke={section.color}
        strokeWidth="2px"
        fill={section.color}
        fillOpacity={0.1}
        dot={true}
        key={section._id + props.data.name}
      />
    );
  });

  const areas = sections.map((section) => {
    const color = randomColor();
    return (
      <Area
        type="monotone"
        dataKey={section._id}
        stackId="1"
        stroke={color}
        fill={color}
        opacity={0.6}
      />
    );
  });

  const formatNumbers = (value) => {
    if (value === 0 || value === 1) {
      return 0;
    } else {
      return value - 1;
    }
    return value;
  };

  const customTicks = (props) => {
    const { x, y, textAnchor, verticalAnchor, payload, index } = props;

    let yy;
    let xx;

    let adjust = 0;

    if (index === 1) {
      adjust = -20;
    } else if (index === myData.length) {
      adjust = 20;
    }

    if (x >= 440 && x <= 460) {
      if (y > 350) {
        yy = y + 20;
      } else {
        yy = y - 20;
      }
      xx = x + (x - 450);
    } else if (x < 440) {
      xx = x - 15;
      yy = y + adjust;
    } else if (x > 460) {
      xx = x + 15;
      yy = y + adjust;
    }

    return (
      <Text
        width={150}
        x={xx}
        y={yy}
        textAnchor={textAnchor}
        verticalAnchor={verticalAnchor}
      >
        {payload.value}
      </Text>
    );
  };

  return (
    <div
      style={{ marginBottom: "10px", textAlign: 'center' }}
      className={props.index > 0 ? "page-break-before" : ""}
    >
      <h3>* {props.data.name} *</h3>
      <RadarChart
        // cx={450}
        // cy={450}
        outerRadius={280}
        width={800}
        height={710}
        data={myData}
      >
        <PolarGrid gridType="circle" />
        <PolarAngleAxis dataKey="shortName" tick={customTicks} />
        <PolarRadiusAxis
          angle={90 - 360 / myData.length}
          domain={[0, max]}
          tickFormatter={formatNumbers}
        />
        {radars}
        <Legend />
      </RadarChart>
      <div style={{ marginTop: "0px" }} />
      {/* <hr /> */}
    </div>
  );
};

const MemoizedPolarGid = React.memo(Polar);

export default MemoizedPolarGid;
