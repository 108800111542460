import React from "react";
import { useSelector } from "react-redux";

export default function Profile() {
  const user = useSelector((state) => state.auth);
  return (
    <div className='pageContainer'>
      <h1>{user.user.name}</h1>
      <div>{user.user.description}</div>
    </div>
  );
}
