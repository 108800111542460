import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import PublicRoute from "./PublicRoute";
import AdminRoute from "./AdminRoute";
import UserRoute from "./UserRoute";
import AdminAndUserRoute from "./AdminAndUserRoute";

import NotFound from "../components/pages/public/NotFound";
import Loging from "../components/pages/public/Login";

import AdminDashboard from "../components/pages/auth/Admin/AdminDashboard";
import UserDashboard from "../components/pages/auth/Users/UserDashboard";
import Profile from "../components/pages/auth/Common/Profile";
import Preschool from "../components/pages/auth/Common/Preschool";
import Organisations from "../components/pages/auth/Common/Organisations";

const AppRouter = () => (
  <Router>
    <Switch>
      <AdminRoute
        path='/admindashboard'
        component={AdminDashboard}
        exact={true}
      />
      <UserRoute path='/userdashboard' component={UserDashboard} exact={true} />
      <AdminAndUserRoute path='/profile' component={Profile} exact={true} />
      <AdminAndUserRoute
        path='/organisations'
        component={Organisations}
        exact={true}
      />
      <AdminAndUserRoute
        path='/preschool/:id'
        component={Preschool}
        exact={true}
      />
      <PublicRoute path='/' component={Loging} exact={true} />
      <AdminRoute component={NotFound} />
      <PublicRoute component={NotFound} />
    </Switch>
  </Router>
);

export default AppRouter;
