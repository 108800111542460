export const getMilestone = (id, pedagogyTree) => {
  let result;

  const parsePedagogicalArea = (pedagogicalArea) => {
    pedagogicalArea.centralcontent.forEach((centralcontent) => {
      parseCentralContent(centralcontent, pedagogicalArea);
    });
  };

  const parseCentralContent = (centralcontent, pedagogicalArea) => {
    const parse = centralcontent.milestones.forEach((milestone) => {
      if (milestone._id === id) {
        result = {
          milestone: milestone,
          centralContent: centralcontent,
          pedagogicalArea: pedagogicalArea,
        };
      }
    });
  };

  pedagogyTree.forEach((pedagogicalArea) => {
    parsePedagogicalArea(pedagogicalArea);
  });

  return result;
};
