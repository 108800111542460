import React from "react";
import Icon from "react-web-vector-icons";

export default function TrendBox({ text, now, before, title }) {
  const trendIcon = (
    <Icon
      font='Feather'
      name={now > before ? "arrow-up-right" : "arrow-down-right"}
      size={20}
      color={now > before ? "green" : "red"}
    />
  );
  return (
    <div className='trendboxContainer'>
      <h3>{title}</h3>
      <div className='trendboxText'>{text}</div>
      <div className='trendboxDataContainer'>
        <div className='trendboxNumber'>
          {now} {/*trendIcon*/}
        </div>
        {/*<div className='trendboxPreviousWeek'>({before})</div>*/}
      </div>
    </div>
  );
}
