import React from "react";
import PedagogicalAreaStats from "./PedagogicalAreaStats";
import MemoizedPolarGrid from "../polarGrid/PolarGrid";
const SectionsDetailedStats = React.memo((props) => {
  let nothing = "";

  return (
    <div>
      <h3>{props.data.section}</h3>
      {props.data.data.map((item) => {
        if (item.value) {
          return (
            <PedagogicalAreaStats
              data={item}
              max={props.max}
              key={props.data.section + item.name}
            />
          );
        } else {
          nothing += nothing === "" ? item.name : `, ${item.name}`;
        }
      })}
      {nothing !== "" && (
        <div style={{ paddingTop: "12px" }}>
          Ingen aktivitet genomfördes och dokumenterades inom {nothing} på{" "}
          {props.data.section}
        </div>
      )}

    </div>
  );
});

export default SectionsDetailedStats;
