import React, { useState, useEffect } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import "moment";
import "moment/locale/sv";
import { getMilestone } from "../../utils/getPedagogyItem";
import { blobToURL, fromURL } from "image-resize-compress";

const ResizedImage = (uri) => {
    const [image, setImage] = useState();
    const handleBlob = (sourceUrl) => {
        console.log(sourceUrl);
        const quality = 100;
        const width = "auto";
        const height = 120;
        //const format = "webp";

        fromURL(sourceUrl, quality, width, height).then((blob) => {
            blobToURL(blob).then((url) => {
                setImage(url);
            });
        });
    };
    useEffect(() => {
        handleBlob(uri.uri);
    }, []);
    return image ? (
        <img src={image} alt="Activity main" className="redLineImage" />
    ) : (
        <div />
    );
};

const RedLine = ({ section, documentations }) => {
    const pedagogicalAreas = useSelector(
        (state) => state.pedagogy.pedagogicalAreas,
    );
    const preschoolGoals = useSelector(
        (state) => state.organisations.preschoolGoals,
    );
    let [documentationsList, setDocumentationsList] = useState("");
    moment().locale("sv");
    useEffect(() => {
        if (pedagogicalAreas) {
            const output = documentations
                .filter((item) =>
                    moment(item.plannedDate).isBetween(
                        moment().subtract(1, "month").startOf("month"),
                        moment().subtract(1, "month").endOf("month"),
                    ),
                )
                .map((item, index) => {
                    const doc = item.documentation;
                    if (doc.createddate) {
                        let firstMilestone = getMilestone(
                            doc.achievedMilestones[0],
                            pedagogicalAreas,
                        );

                        const centralContentHeader =
                            firstMilestone &&
                            firstMilestone.centralContent.header;

                        let imagePath;
                        if (doc.pictures.length > 0) {
                            imagePath = `https://api.edchild.com/${doc.pictures[0].small.path}`;
                        } else if (item.images.length > 0) {
                            imagePath = `https://api.edchild.com/${item.images[0].path}`;
                        } else {
                            imagePath = centralContentHeader;
                        }
                        return (
                            <li
                                key={
                                    index.toString() +
                                    item.section._id +
                                    item.documentation._id
                                }
                                className="page-break-inside-avoid"
                            >
                                <div className="redLineContainer">
                                    <div>
                                        <ResizedImage uri={imagePath} />
                                    </div>
                                    <div>
                                        <div className="redLineTitle">
                                            {moment(item.plannedDate).format(
                                                "dddd DD MMMM YYYY",
                                            )}{" "}
                                            - {item.name}
                                        </div>
                                        <div className="redLineMilestone">
                                            {doc.achievedMilestones.map(
                                                (milestone, index) => {
                                                    const myMilestone =
                                                        getMilestone(
                                                            milestone,
                                                            pedagogicalAreas,
                                                        );
                                                    return (
                                                        myMilestone && (
                                                            <div
                                                                key={
                                                                    index +
                                                                    item._id +
                                                                    milestone
                                                                }
                                                            >
                                                                <span className="redLinePedagogicalArea">
                                                                    {
                                                                        myMilestone
                                                                            .pedagogicalArea
                                                                            .name
                                                                    }
                                                                </span>
                                                                :{" "}
                                                                {
                                                                    myMilestone
                                                                        .milestone
                                                                        .name
                                                                }
                                                            </div>
                                                        )
                                                    );
                                                },
                                            )}
                                            {preschoolGoals &&
                                                preschoolGoals.length > 0 &&
                                                doc.achievedPreschoolGoals.map(
                                                    (goal, index) => {
                                                        const preschoolGoal =
                                                            preschoolGoals.filter(
                                                                (item) =>
                                                                    item._id ===
                                                                    goal,
                                                            )[0];
                                                        return (
                                                            <div
                                                                key={
                                                                    index + goal
                                                                }
                                                            >
                                                                <span className="redLinePedagogicalArea">
                                                                    {
                                                                        preschoolGoal.name
                                                                    }
                                                                </span>
                                                                :{" "}
                                                                {
                                                                    preschoolGoal.description
                                                                }
                                                            </div>
                                                        );
                                                    },
                                                )}
                                        </div>
                                        {doc.childrensComment && (
                                            <div className="redLineComment">
                                                <b>
                                                    Barnens tankar och
                                                    reflektioner:{" "}
                                                </b>
                                                {doc.childrensComment}
                                            </div>
                                        )}
                                        {doc.comment && (
                                            <div className="redLineComment">
                                                <b>
                                                    Pedagogens observationer och
                                                    reflektioner:{" "}
                                                </b>
                                                {doc.comment}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </li>
                        );
                    } else {
                        return null;
                    }
                });
            setDocumentationsList(output);
            // setDocumentationsList(output.filter((item) => moment(item.plannedDate).isBetween(moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month'))));
        }
    }, [documentations, pedagogicalAreas, preschoolGoals]);

    return (
        <div>
            <h3>{section.name}</h3>
            <h4>
                Hur är det att vara barn på vår avdelning - dokumentationer
                under den gångna månaden
            </h4>
            {documentationsList.length > 0 ? (
                <ul className="redLine">{documentationsList}</ul>
            ) : (
                <div>Ingen aktivitet har dokumenterats</div>
            )}
        </div>
    );
};

const MemoizedRedLine = React.memo(RedLine);

export default MemoizedRedLine;
