import React from 'react'
import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';

export default function HorizontalBarChart(props) {
    const data = props.goals
    return (
        <div>
            <h4>Förskolans egna mål</h4>
            {props.goals.length > 0 ? (
                <BarChart
                    width={800}
                    height={data.length * 100}
                    data={data}
                    layout="vertical"
                    margin={{ top: 5, right: 30, left: 120, bottom: 5 }}
                >
                    <XAxis type="number" />
                    <YAxis type="category" dataKey="name" />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Tooltip />
                    <Legend />
                    {/* <Bar dataKey="pv" fill="#8884d8" /> */}
                    <Bar dataKey="count" fill="#82ca9d" barSize={20} name="För hela förskolan" />
                </BarChart>
            ) : (
                    <div>Förskolan har inte lagt till egna mål i Fokus Förskola</div>
                )}

            <div style={{ marginTop: '20px' }} />
            <hr />
        </div>
    )
}
