import axios from "axios";
import {
  GET_EMPLOYEE,
  EMPLOYEE_LOADING,
  GET_ERRORS,
  CLEAR_CURRENT_EMPLOYEE,
} from "./types";

//Get current employee profile
export const getCurrentEmployee = () => (dispatch) => {
  dispatch(setEmployeeLoading());
  axios
    .get("user/current")
    .then((res) =>
      dispatch({
        type: GET_EMPLOYEE,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err,
      })
    );
};

//Employee loading
export const setEmployeeLoading = () => {
  return {
    type: EMPLOYEE_LOADING,
  };
};

//Clear employee
export const clearCurrentEmployee = () => {
  return {
    type: CLEAR_CURRENT_EMPLOYEE,
  };
};
