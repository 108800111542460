import { combineReducers } from "redux";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import employeesReducer from "./employeesReducer";
import pedagogyReducer from "./pedagogyReducer";
import organisationsReducer from "./organisationsReducer";
import activityreducer from "./activityReducer";

export default combineReducers({
  auth: authReducer,
  errors: errorReducer,
  employees: employeesReducer,
  pedagogy: pedagogyReducer,
  organisations: organisationsReducer,
  activity: activityreducer,
});
