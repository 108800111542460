import React from "react";
import { PieChart, Pie, Sector, Cell } from "recharts";
import Icon from "react-web-vector-icons";

const RADIAN = Math.PI / 180;

const MyPie = ({ data }) => {
    const renderCustomizedLabel = ({
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        percent,
        value,
        payload,
        index,
    }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 1.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
        let myText;

        switch (payload.name) {
            case "one":
                myText = "mindre bra";
                break;
            case "two":
                myText = "bra";
                break;
            case "three":
                myText = "mycket bra";
                break;
        }

        return (
            <text
                x={x}
                y={y > 120 ? y + 10 : y - 10}
                fill="black"
                textAnchor={x < cx ? "start" : "end"}
                dominantBaseline="central"
            >
                {`${myText} (${payload.value})`}
            </text>
        );
    };

    const pieData = [
        { value: data.oneStar, name: "one", color: "red" },
        { value: data.twoStars, name: "two", color: "orange" },
        { value: data.threeStars, name: "three", color: "green" },
    ];

    const filtered = pieData.filter((item) => item.value !== 0);

    return (
        <PieChart width={250} height={300}>
            <Pie
                data={filtered}
                cx={120}
                cy={150}
                label={renderCustomizedLabel}
                //label={pieData}
                outerRadius={100}
                innerRadius={50}
                fill="#8884d8"
                dataKey="value"
                labelLine={true}
                isAnimationActive={false}
            >
                {filtered.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={filtered[index].color} />
                ))}
            </Pie>
        </PieChart>
    );
};

const SatisfactionPie = React.memo(MyPie);
export default SatisfactionPie;
