import React from "react";
import Icon from "react-web-vector-icons";

export default function LogoutBtn({ onClick, height }) {
  return (
    <div
      className='logoutbtncontainer'
      style={{
        height: height,
        display: "flex",
        alignItems: "center",
        paddingRight: 10,
        paddingLeft: 10,
      }}
      onClick={() => onClick()}
    >
      <div className='menuItemLink'>
        <div className='menuItemIcon'>
          <Icon
            name='logout'
            font='MaterialCommunityIcons'
            color='white'
            size={20}
          />
        </div>
        Logout
      </div>
    </div>
  );
}
