import React from "react";

export default function Button({ value, disabled, height, width, onClick }) {
  return (
    <div>
      <button type='button' onClick={(e) => onClick(e)} disabled={disabled}>
        {value}
      </button>
    </div>
  );
}
