import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import HeaderBar from "../components/header/HeaderBar";

export const UserRoute = ({ component: Component, ...rest }) => {
  const user = useSelector((state) => state.auth.user);
  return (
    <Route
      {...rest}
      component={(props) =>
        user.id && !user.isAdmin ? (
          <div>
            <HeaderBar {...props} />
            <Component {...props} />
            {/*<Footer />*/}
          </div>
        ) : (
          <Redirect to='/' />
        )
      }
    />
  );
};

export default UserRoute;
