import React from "react";
import { Link, useLocation } from "react-router-dom";
import Icon from "react-web-vector-icons";

const MenuItemLink = ({ text, link, height, icon, keepIconSpace }) => {
  const location = useLocation();
  //const linkBgColor = location.pathname === link ? "#ff7b00" : null;
  let linkIcon;
  if (icon) {
    linkIcon = (
      <div className='menuItemIcon' style={{ width: 20, paddingRight: 4 }}>
        <Icon
          name={icon.name}
          font={icon.font}
          color={icon.color ? icon.color : "white"}
          size={icon.size}
        />
      </div>
    );
  } else {
    linkIcon = (
      <div className='menuItemIcon' style={{ width: 20, paddingRight: 4 }}>
        {" "}
      </div>
    );
  }

  let linkComponent;
  if (link.substr(0, 4) === "http") {
    linkComponent = (
      <a href={link} target='_blank' className='menuItemLink'>
        {(icon || keepIconSpace) && linkIcon}
        {text}
      </a>
    );
  } else {
    linkComponent = (
      <Link to={link} className='menuItemLink'>
        {(icon || keepIconSpace) && linkIcon}
        {text}
      </Link>
    );
  }

  return <div className='menuItemLinkContainer'>{linkComponent}</div>;
};

export default MenuItemLink;
