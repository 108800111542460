import {
  SET_ALL_ORGANISATIONS,
  SET_ORGANISATIONS_LOADING,
  SET_ORGANISATION_EMPLOYEMENTS,
  SET_PRESCHOOL_TRENDS,
  SET_PRESCHOOL_STATS,
  SET_PRESCHOOL_FOCUS_AREA,
  SET_PRESCHOOL_DOCUMENTATIONS,
  SET_PRESCHOOL_GOALS,
  SET_PRESCHOOL_EMPLOYEES
} from "../actions/types";

const initialState = {
  pedagogicalAreas: undefined,
  loading: false,
  preschoolStats: null,
  preschoolTrends: null,
  preschoolFocusArea: null,
  preschoolDocumentations: null,
  preschoolGoals: null,
  preschoolEmployees: null
};

const randomColor = () =>
  ("#" + ((Math.random() * 0xffffff) << 0).toString(16) + "000000").slice(0, 7);

const pedagogyReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ORGANISATIONS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SET_ALL_ORGANISATIONS:
      return {
        ...state,
        organisations: action.payload,
        loading: false,
      };

    case SET_ORGANISATION_EMPLOYEMENTS:

    case SET_PRESCHOOL_STATS:
      const stats = action.payload
        ? action.payload.map((item) => {
          item.color = randomColor();
          item.centralcontents = item.centralcontents
            .filter((cc) => cc.name !== "TA BORT TACK")
            .map((cc) => {
              cc.milestones = cc.milestones.filter(
                (ms) => ms.name !== "TA BORT TACK"
              );
              return cc;
            });
          return item;
        })
        : action.payload;

      return {
        ...state,
        preschoolStats: stats,
      };

    case SET_PRESCHOOL_TRENDS:
      return {
        ...state,
        preschoolTrends: action.payload,
      };

    case SET_PRESCHOOL_FOCUS_AREA:
      return {
        ...state,
        preschoolFocusArea: action.payload,
      };

    case SET_PRESCHOOL_GOALS:
      return {
        ...state,
        preschoolGoals: action.payload,
      };

    case SET_PRESCHOOL_EMPLOYEES:
      return {
        ...state,
        preschoolEmployees: action.payload,
      };

    case SET_PRESCHOOL_DOCUMENTATIONS:
      return {
        ...state,
        preschoolDocumentations: action.payload,
      };

    default:
      return state;
  }
};

export default pedagogyReducer;
