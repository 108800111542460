import React, { useRef, useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

const PedagogicalAreaStats = React.memo(({ data, max }) => {
  let myData = [];
  let bars = [];

  const randomColor = () =>
    ("#" + ((Math.random() * 0xffffff) << 0).toString(16) + "000000").slice(
      0,
      7
    );

  let numberOfMileStones = 0;

  data.centralcontents
    //.filter((centralcontent) => centralcontent.value > 0)
    .map((centralcontent) => {
      if (centralcontent.value > 0) {
        let item = {};
        item.name = centralcontent.name;
        item.cccount = centralcontent.value;
        item.shortName = centralcontent.name.substr(0, 20);
        item.total = 0;
        centralcontent.milestones
          //.filter((milestone) => milestone.value > 0)
          .map((milestone, index) => {
            numberOfMileStones += 1;
            item[milestone.name + `(${milestone.value})`] = milestone.value;
            bars.push(
              <Bar
                dataKey={milestone.name + `(${milestone.value})`}
                fill={randomColor()}
              />
            );
            item.total += milestone.value;
          });
        myData.push(item);
      }
    });

  const renderCentralContentTick = (tickProps) => {
    const { x, y, payload, index } = tickProps;
    const { value, offset } = payload;

    const currentCentralContent = myData[index];
    const nextCentralContent = myData[index + 1];

    const isLast = index === myData.length - 1;

    if (
      isLast ||
      (nextCentralContent &&
        currentCentralContent.name !== nextCentralContent.name)
    ) {
      let pathX = Math.floor(isLast ? x + offset * 2 : x) + 0.5;

      return <path d={`M${pathX},${y - 19}v${-20}`} stroke='red' />;
    }
    return null;
  };

  //console.log(myData);

  return (
    <div>
      <h5>{data.name}</h5>

      <BarChart
        width={800}
        height={300 + numberOfMileStones * 20}
        data={myData}
        margin={{
          top: 5,
          right: 10,
          left: 10,
          bottom: 5,
        }}
        barSize={20}
      >
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='shortName' />

        <YAxis type='number' domain={[0, max]} />
        <Tooltip />
        {bars}
        <Legend layout='vertical' />
      </BarChart>
    </div>
  );
});

export default PedagogicalAreaStats;
