import React, { useState } from "react";
import { useDispatch } from "react-redux";
import TextInput from "../../inputs/TextInput";
import Button from "../../inputs/Button";
import { loginUser } from "../../../store/actions";

export default function Login() {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [emailErrors, setEmailErrors] = useState(null);
  const [password, setPassword] = useState("");
  const [passwordErrors, setPasswordErrors] = useState(null);
  const [isDisabledButton, setIsDisabledButton] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(loginUser({ email: email, password: password }));
  };

  return (
    <div className='App-body'>
      <div>
        <h1>Login</h1>
        <form onSubmit={(e) => onSubmit(e)}>
          <div>
            <TextInput
              value={email}
              label='Email'
              placeholder='Din mail adress'
              type='email'
              error={emailErrors}
              onChange={setEmail}
            />
            <TextInput
              value={password}
              label='Lösenord'
              placeholder='Dit lösenord'
              type='password'
              error={passwordErrors}
              onChange={setPassword}
            />
            <Button
              value='Logga in'
              onClick={onSubmit}
              disabled={isDisabledButton}
            />
          </div>
        </form>
      </div>
    </div>
  );
}
