import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function Organisations() {
    const organisations = useSelector((state) => state.auth.employements);
    const user = useSelector((state) => state.auth.user);

    const output =
        organisations &&
        organisations.map((item) => {
            if (item._id !== "5e31caf61af998004cba0d69") {
                return (
                    <div className="boxContainer">
                        <h4>{item.name}</h4>
                        <hr />
                        <ul>
                            {item.preschools.map((preschool) => {
                                return (
                                    <li
                                        key={
                                            "preschoolListItem" + preschool._id
                                        }
                                    >
                                        <Link
                                            to={"/preschool/" + preschool._id}
                                        >
                                            {preschool.name}
                                        </Link>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                );
            }
        });
    return (
        <div className="pageContainer">
            <h1>Organisationer</h1>
            {user.isAdmin && (
                <div>Här visas inte organisationen "användare untan avtal"</div>
            )}
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                }}
            >
                {output}
            </div>
        </div>
    );
}
