import React, { useState } from "react";
import { PieChart, Pie, Sector, Cell } from "recharts";
import Icon from "react-web-vector-icons";

const RADIAN = Math.PI / 180;

const SectionGlobalStats = ({ data, employees }) => {
  let filtered = data.filter((item) => item.value !== 0);
  const empty = data.filter((item) => item.value === 0);
  const [animated, setAnimated] = useState(true);

  const renderLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    payload,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 1.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="#636466"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${payload.name} (${payload.value})`}
      </text>
    );
  };

  const noData = [{ value: 1, name: "empty", color: "black" }];

  const dataSource = filtered.length > 0 ? filtered : noData;

  return (
    <div>
      <PieChart width={200} height={200}>
        <Pie
          data={dataSource}
          cx={100}
          cy={100}
          //label={renderLabel}
          outerRadius={80}
          innerRadius={30}
          fill="#8884d8"
          dataKey="value"
          //labelLine={true}
          paddingAngle={filtered.length > 0 ? 2 : 0}
          isAnimationActive={false}
        >
          {dataSource.map((entry, index) => {
            if (filtered.length > 0) {
              return <Cell key={`cell-${index}`} fill={entry.color} />;
            } else {
              return <Cell key={`cell-${index}`} fill={"#636466"} />;
            }
          })}
        </Pie>
      </PieChart>
      <div>
        {filtered.map((item) => (
          <div key={item.name}>
            <Icon
              name="dot-circle-o"
              font="FontAwesome"
              size={16}
              color={item.color}
            />{" "}
            {item.name} ({item.value})
          </div>
        ))}
        {empty.map((item) => (
          <div key={item.name}>
            <Icon
              name="dot-circle-o"
              font="FontAwesome"
              size={16}
              color="black"
            />{" "}
            {item.name} ({item.value})
          </div>
        ))}
      </div>
      <hr />
      {employees &&
        employees.length > 0 &&
        employees.map((employee, index) => (
          <div key={"employee" + index + employee._id}>{employee.name}</div>
        ))}
    </div>
  );
};

const MemoizedSectionGlobalStats = React.memo(SectionGlobalStats);

export default MemoizedSectionGlobalStats;
