import axios from "axios";
import {
  GET_ERRORS,
  SET_ALL_ORGANISATIONS,
  SET_ORGANISATIONS_LOADING,
  SET_ORGANISATION_EMPLOYEMENTS,
  SET_PRESCHOOL_TRENDS,
  SET_PRESCHOOL_STATS,
  SET_PRESCHOOL_FOCUS_AREA,
  SET_PRESCHOOL_DOCUMENTATIONS,
  SET_PRESCHOOL_GOALS,
  SET_PRESCHOOL_EMPLOYEES
} from "./types";

export const getAllOrganisations = () => (dispatch) => {
  dispatch(setOrganisationLoading());
  return new Promise((resolve, reject) => {
    axios
      .get("/organisations/preschoolorganisation/all")
      .then((res) => {
        dispatch({
          type: SET_ALL_ORGANISATIONS,
          payload: res.data,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        reject(err);
      });
  });
};

//Employee loading
export const setOrganisationLoading = () => {
  return {
    type: SET_ORGANISATIONS_LOADING,
  };
};

//Save or update organisation
export const saveOrganisation = (data, action) => (dispatch) => {
  dispatch(setOrganisationLoading());
  const url = !!data._id
    ? `/organisations/${action}/${data._id}`
    : `/organisations/${action}/new`;
  const urlAction = !!data._id ? axios.put : axios.post;
  console.log({ data: data, url: url, urlAction: urlAction });
  return new Promise((resolve, reject) => {
    urlAction(url, data)
      .then((res) => {
        dispatch(getAllOrganisations())
          .then(() => resolve(res.data))
          .catch((err) => reject(err.response.data));
      })
      .catch((err) => {
        dispatch(getAllOrganisations())
          .then(() => reject(err))
          .catch(() => reject(err));
      });
  });
};

export const getOrganisationEmployees = (data, organisation) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/organisations/employements", data)
      .then((res) => {
        console.log(res.data);
        res.organisation = organisation;
        dispatch({
          type: SET_ORGANISATION_EMPLOYEMENTS,
          payload: res.data,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        reject(err);
      });
  });
};

export const getPreschoolTrends = (id) => (dispatch) => {
  dispatch({
    type: SET_PRESCHOOL_TRENDS,
    payload: null,
    loadind: true,
  });
  axios
    .get("/v2/stats/preschool/trends/" + id)
    .then((res) => {
      dispatch({
        type: SET_PRESCHOOL_TRENDS,
        payload: res.data,
        loadind: false,
      });
    })
    .catch((err) => console.log(err.response));
};

export const getPreschoolStats = (id) => (dispatch) => {
  dispatch({
    type: SET_PRESCHOOL_STATS,
    payload: null,
    loadind: true,
  });
  axios
    .get("/v2/stats/preschool/pedagogysummary/" + id)
    .then((res) => {
      dispatch({
        type: SET_PRESCHOOL_STATS,
        payload: res.data,
        loadind: false,
      });
    })
    .catch((err) => console.log(err.response));
};

export const getPreschoolFocusArea = (id) => (dispatch) => {
  dispatch({
    type: SET_PRESCHOOL_FOCUS_AREA,
    payload: null,
    loadind: true,
  });
  axios
    .get("/v2/stats/preschool/getfocusareas/" + id)
    .then((res) => {
      dispatch({
        type: SET_PRESCHOOL_FOCUS_AREA,
        payload: res.data,
        loadind: false,
      });
    })
    .catch((err) => console.log(err.response));
};

export const getPreschoolGoals = (id) => dispatch => {
  dispatch({
    type: SET_PRESCHOOL_GOALS,
    payload: null,
    loadind: true,
  });
  axios
    .get("/v2/stats/preschool/preschoolGoalsSummary/" + id)
    .then((res) => {
      dispatch({
        type: SET_PRESCHOOL_GOALS,
        payload: res.data,
        loadind: false,
      });
    })
    .catch((err) => console.log(err.response));

}

export const getPreschoolEmployees = (id) => dispatch => {
  dispatch({
    type: SET_PRESCHOOL_EMPLOYEES,
    payload: null,
    loadind: true,
  });
  axios
    .get("/v2/stats/preschool/employees/" + id)
    .then((res) => {
      dispatch({
        type: SET_PRESCHOOL_EMPLOYEES,
        payload: res.data,
        loadind: false,
      });
    })
    .catch((err) => console.log(err.response));

}

export const getPreschoolDocumentations = (id) => (dispatch) => {
  dispatch({
    type: SET_PRESCHOOL_DOCUMENTATIONS,
    payload: null,
    loadind: true,
  });
  axios
    .get("/v2/stats/preschool/alldocumentations/" + id)
    .then((res) => {
      dispatch({
        type: SET_PRESCHOOL_DOCUMENTATIONS,
        payload: res.data,
        loadind: false,
      });
    })
    .catch((err) => console.log(err.response));
};
