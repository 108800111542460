import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import Icon from "react-web-vector-icons";

import { logoutUser } from "../../store/actions/authActions";
import LogoutBtn from "../buttons/logoutBtn/LogoutBtn";
import MenuItemLink from "../buttons/menuItems/MenuItemLink";

export default function HeaderBar() {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);
  const employements = useSelector((state) => state.auth.employements);

  const changeIcon = (
    <Icon
      font="MaterialCommunityIcons"
      name="autorenew"
      size={20}
      color="#636466"
    />
  );

  let changeOrganisation;

  if (employements) {
    if (localStorage.currentOrganisationId) {
      //store.dispatch(setCurrentOrganisation())
    } else {
      if (employements[0].preschools[0].employement) {
        localStorage.setItem(
          "currentOrganisationId",
          JSON.stringify({
            _id: employements[0].preschools[0]._id,
            name: employements[0].preschools[0].name,
            origin: user.isAdmin
              ? null
              : employements[0].preschools[0].employement.origin,
          })
        );
      } else {
        localStorage.setItem(
          "currentOrganisationId",
          JSON.stringify({
            _id: employements[0]._id,
            name: employements[0].name,
            origin: user.isAdmin ? null : employements[0].employement.origin,
          })
        );
      }
    }

    if (user.isAdmin) {
      changeOrganisation = (
        <div style={{ color: "white" }}>Inloggad som admin</div>
      );
    } else {
      const currentOrganisation = JSON.parse(
        localStorage.currentOrganisationId
      );
      const origin = currentOrganisation.origin;
      changeOrganisation = (
        <div style={{ color: "white" }}>
          {currentOrganisation.name} {employements.lenght > 1 && changeIcon}
        </div>
      );
    }
  }

  let buttons;

  if (user.isAdmin) {
    buttons = (
      <div className="menuButtonsContainer">
        <MenuItemLink link="/appcontent" height={28} text="innehåll" />
        <MenuItemLink link="/organisations" height={28} text="organisationer" />
        <MenuItemLink link="/users" height={28} text="användare" />
      </div>
    );
  } else {
    buttons = (
      <div className="menuButtonsContainer">
        <MenuItemLink link="/preschools" height={28} text="förskolor" />
        <MenuItemLink link="/profile" height={28} text="min profil" />
        <div
          style={{
            display: "flex",
            backgroundColor: "white",
            width: "40px",
            height: "40px",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "20px",
          }}
        >
          <Icon
            font="MaterialCommunityIcons"
            name="settings-outline"
            size={22}
            color="#fdb913"
          />
        </div>
      </div>
    );
  }

  const logout = () => {
    dispatch(logoutUser());
  };

  return (
    <div className="header">
      <div className="menuButtonsContainer">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          {/* <Link to="/" className="headerLogo"> */}
          <img
            src="/pictures/edchild_icon_yellow_grey.png"
            height="60"
            alt="Edchilds logo"
          />
          <img
            src="/pictures/fokusforskola_logo_grey.png"
            height="60"
            alt="Fokus förskola logo"
          />
          {/* </Link> */}
        </div>
        <div className="printHidden">{changeOrganisation}</div>
      </div>

      <div className="headerButtonsContainer printHidden">
        {buttons}
        <LogoutBtn onClick={logout} />
      </div>
    </div>
  );
}
