import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  getPreschoolStats,
  getPreschoolTrends,
  getPreschoolFocusArea,
  getPreschoolDocumentations,
  getPreschoolGoals,
  getPreschoolEmployees,
} from "../../../../store/actions/index";

import TrendBox from "../../../trendBox/TrendBox";
import SatisfactionPie from "../../../pieChart/SatisfactionPie";
import SectionGlobalStats from "../../../pieChart/SectionGlobalStats";
import SectionsDetailedStats from "../../../bars/SectionsDetailedStats";
import MemoizedPolarGrid from "../../../polarGrid/PolarGrid";
import MemoizedRedLine from "../../../redLine/RedLine";
import HorizontalBarChart from "./../../../horizontalBarChart/HorizontalBarChart";

import moment from "moment";
import "moment/locale/sv";

const randomColor = () =>
  ("#" + ((Math.random() * 0xffffff) << 0).toString(16) + "000000").slice(0, 7);

export default function Preschool() {
  moment().locale("se");
  function randomInteger(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  const dispatch = useDispatch();
  const { id } = useParams();

  const colors = [
    "#e85212",
    "#59e812",
    "#1287e8",
    "#12e872",
    "#b612e8",
    "#e81284",
    "#32e812",
    "#992929",
    "#996129",
    "#9da3db",
    "#cfdb9d",
    "#0cfca8",
  ];

  const { employements, user } = useSelector((state) => state.auth);

  const [myPreschool, setMyPreschool] = useState(null);

  useEffect(() => {
    setMyPreschool(null);
    dispatch(getPreschoolStats(id));
    dispatch(getPreschoolTrends(id));
    dispatch(getPreschoolFocusArea(id));
    dispatch(getPreschoolDocumentations(id));
    dispatch(getPreschoolGoals(id));
    dispatch(getPreschoolEmployees(id));

    employements &&
      employements.map((employement) => {
        employement.preschools.map((preschool) => {
          if (preschool._id === id) {
            setMyPreschool(preschool);
          }
        });
      });
  }, [id, employements]);

  const {
    preschoolStats,
    preschoolTrends,
    preschoolFocusArea,
    preschoolDocumentations,
    preschoolGoals,
    preschoolEmployees,
  } = useSelector((state) => state.organisations);

  function splitIntoChunk(arr, chunk) {
    let tempArray = [];
    for (let i = 0; i < arr.length; i += chunk) {
      tempArray.push(arr.slice(i, i + chunk));

      if (i + chunk >= arr.length) {
        return tempArray;
      }
    }
  }

  let sectionsStats = null;
  let sectionsDetailedStats = [];
  let sectionsDetailedStatsRender;
  if (myPreschool && preschoolStats) {
    const sectionChunks = splitIntoChunk(myPreschool.sections, 3);

    sectionsStats = sectionChunks.map((sections) => {
      return (
        <div className="page-break-after statsContainer">
          {sections.map((section) => {
            const data = preschoolStats.map((stats) => {
              const count = stats.count.filter((countItem) => {
                return countItem._id === section._id;
              });
              return {
                name: stats.name,
                value: count[0].count,
                color: stats.color,
              };
            });

            return (
              <div
                key={"stats" + section._id}
                className="sectionGlobalStatsContainer"
              >
                <div className="smallTitle">{section.name}</div>
                <div
                  style={{
                    textAlign: "center",
                  }}
                >{`${section.age.min} till ${section.age.max} år`}</div>

                <SectionGlobalStats
                  data={data}
                  employees={
                    preschoolEmployees &&
                    preschoolEmployees.filter(
                      (employee) => employee.section === section._id
                    )
                  }
                />
              </div>
            );
          })}
        </div>
      );
    });

    let max = 0;

    sectionsDetailedStats = myPreschool.sections.map((section) => {
      const data = preschoolStats.map((stats) => {
        const count = stats.count.filter((countItem) => {
          return countItem._id === section._id;
        });
        const centralContents = stats.centralcontents.map((cc) => {
          const count = cc.count.filter((countItem) => {
            return countItem._id === section._id;
          });

          const milestones = cc.milestones.map((ms) => {
            const count = ms.count.filter((countItem) => {
              return countItem._id === section._id;
            });
            max = count[0].count > max ? count[0].count : max;
            return {
              name: ms.name,
              value: count[0].count,
              color: stats.color,
            };
          });

          return {
            name: cc.name,
            value: count[0].count,
            color: stats.color,
            milestones: milestones,
          };
        });
        return {
          name: stats.name,
          value: count[0].count,
          color: stats.color,
          centralcontents: centralContents,
        };
      });

      return { section: section.name, data: data, _id: section._id };
    });
    sectionsDetailedStatsRender = sectionsDetailedStats.map((item, index) => {
      return (
        <SectionsDetailedStats
          data={item}
          key={index + item.section}
          max={max}
        />
      );
    });
  }

  let trends;
  let academicYearTrends;
  let satisfactionPie;
  let satisfactionPieAcademicYear;
  let weeklySummary;
  if (preschoolTrends) {
    trends = (
      <div className="trendBoxesContainer">
        <TrendBox
          title="1. Planera"
          now={preschoolTrends.now.numberOfPlannedActivities}
          before={preschoolTrends.previousMonth.numberOfPlannedActivities}
          text="Antal planerade aktiviteter"
        />
        <TrendBox
          title="2. Dokumentera och reflektera"
          now={preschoolTrends.now.numberOfDocumentations}
          before={preschoolTrends.previousMonth.numberOfDocumentations}
          text="Antal genomförda och dokumenterade aktiviteter"
        />

        <TrendBox
          title="3. Utvärdera och analysera"
          now={preschoolTrends.now.numberOfWeeklyEvaluations}
          before={preschoolTrends.previousMonth.numberOfWeeklyEvaluations}
          text="Antal utvärderingar"
        />
      </div>
    );
    academicYearTrends = (
      <div className="trendBoxesContainer">
        <TrendBox
          title="1. Planera"
          now={preschoolTrends.academicYear?.numberOfPlannedActivities}
          before={preschoolTrends.previousMonth?.numberOfPlannedActivities}
          text="Antal planerade aktiviteter"
        />
        <TrendBox
          title="2. Dokumentera och reflektera"
          now={preschoolTrends.academicYear.numberOfDocumentations}
          before={preschoolTrends.previousMonth.numberOfDocumentations}
          text="Antal genomförda och dokumenterade aktiviteter"
        />

        <TrendBox
          title="3. Utvärdera och analysera"
          now={preschoolTrends.academicYear.numberOfWeeklyEvaluations}
          before={preschoolTrends.previousMonth.numberOfWeeklyEvaluations}
          text="Antal utvärderingar"
        />
      </div>
    );
    const ratings =
      preschoolTrends.now.childrensRating.oneStar +
      preschoolTrends.now.childrensRating.twoStars +
      preschoolTrends.now.childrensRating.threeStars;

    const ratingsAcademicYear =
      preschoolTrends.academicYear.childrensRating.oneStar +
      preschoolTrends.academicYear.childrensRating.twoStars +
      preschoolTrends.academicYear.childrensRating.threeStars;


    satisfactionPie = ratings > 0 && (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          // alignItems: "center",
          // paddingTop: "24px",
        }}
      >
        <h2 style={{ marginBottom: 0 }}>- Gör barnen delaktiga -</h2>
        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', maxWidth: '100%' }}>
          <div>
            <h3>
              Vad tyckte barnen om aktiviteterna från den gångna månaden utifrån
              våra dokumentationer?
            </h3>
            <div className="satisfactionPieContainer">
              <SatisfactionPie data={preschoolTrends.now.childrensRating} />
            </div>
          </div>
          <div>
            <h3>
              Vad tyckte barnen om aktiviteterna sedan läsårets början utifrån
              våra dokumentationer?
            </h3>
            <div className="satisfactionPieContainer">
              <SatisfactionPie data={preschoolTrends.academicYear.childrensRating} />
            </div>
          </div>
        </div>
      </div>
    );

    satisfactionPieAcademicYear = ratingsAcademicYear > 0 && (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          // alignItems: "center",
          // paddingTop: "24px",
          marginTop: "24px",
        }}
      >
        <h3>
          Vad tyckte barnen om aktiviteterna sedan läsårets början utifrån
          våra dokumentationer?
        </h3>
        <div className="satisfactionPieContainer">
          <SatisfactionPie data={preschoolTrends.academicYear.childrensRating} />
        </div>
      </div>
    );

    weeklySummary =
      preschoolTrends.evaluationsAcademicYear.length > 0 && myPreschool ? (
        myPreschool.sections.map((section) => {
          return (
            <div key={weeklySummary + section._id}>
              {/* {preschoolTrends.evaluations.filter(
                (evaluation) => evaluation.sectionId === section._id
              ).length > 0 && <h3>{section.name}</h3>} */}

              <h3 className="page-break-after-avoid">{section.name}</h3>
              {preschoolTrends.evaluationsAcademicYear.filter(
                (evaluation) => evaluation.sectionId === section._id
              ).length === 0 && <div>Ingen utvärdering har genomförts</div>}
              {preschoolTrends.evaluationsAcademicYear
                .filter((evaluation) => evaluation.sectionId === section._id)
                .map((item, index) => {
                  let output = [];
                  item.aboutNextWeek &&
                    output.push(<div>{item.aboutNextWeek}</div>);
                  item.childGroupComment &&
                    output.push(<div>{item.childGroupComment}</div>);
                  item.childrensVoice &&
                    output.push(<div>{item.childrensVoice}</div>);
                  item.concerns && output.push(<div>{item.concerns}</div>);
                  item.safetyComment &&
                    output.push(<div>{item.safetyComment}</div>);

                  let preschoolGoalsRender;
                  if (item.preschoolGoals && item.preschoolGoals.length > 0) {
                    preschoolGoalsRender = (
                      <div>
                        <div
                          style={{
                            paddingTop: "10px",
                            textDecoration: "underline",
                          }}
                        >
                          Analys och åtgärder utifrån förskolans mål
                        </div>
                        {item.preschoolGoals.map((goal) => {
                          return (
                            goal.comment !== "" && (
                              <div style={{
                                fontWeight: "normal",
                                // force line break
                                whiteSpace: "pre-wrap",
                              }}>
                                <div>{goal.name}</div>
                                <div>{goal.comment}</div>
                              </div>
                            )
                          );
                        })}
                      </div>
                    );
                  } else {
                    preschoolGoalsRender = (
                      <div>
                        <div
                          style={{
                            paddingTop: "10px",
                            textDecoration: "underline",
                          }}
                        >
                          Analys och åtgärder utifrån förskolans mål
                        </div>
                        <div>Inget har skrivits</div>
                      </div>
                    );
                  }

                  output.push(preschoolGoalsRender);

                  if (output.length > 0) {
                    return (
                      <div
                        key={"weeksumary" + index + item._id}
                        className="evaluationContainer"
                      >
                        <h4>
                          Perioden{" "}
                          {`${moment(item.dateFrom).format(
                            "YYYY.MM.DD"
                          )} - ${moment(item.dateTo).format("YYYY.MM.DD")}`}
                        </h4>
                        <div style={{ textDecoration: "underline" }}>
                          Analys och åtgärder utifrån läroplanen
                        </div>
                        <div style={{
                          whiteSpace: "pre-wrap",
                        }}>{output}</div>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        key={"weeksumary" + index + item._id}
                        className="evaluationContainer"
                      >
                        <h4>
                          Perioden{" "}
                          {`${moment(item.dateFrom).format(
                            "YYYY.MM.DD"
                          )} - ${moment(item.dateTo).format("YYYY.MM.DD")}`}
                        </h4>
                        <div>Utvärdering utförd utan att skriva text.</div>
                      </div>
                    );
                  }
                })}
            </div>
          );
        })
      ) : (
        <div>Ingen utvärdering har utförts</div>
      );
  }

  let focusArea;
  let pictures = [];
  const noFocusMonth = ["6", "7", "8"];
  const hasFocusArea = !noFocusMonth.includes(moment().format("M"));

  if (preschoolFocusArea) {
    preschoolFocusArea.activities.map((activity) => {
      if (activity.documentation.pictures.length > 0) {
        pictures = pictures.concat(activity.documentation.pictures);
      } else {
        pictures = pictures.concat(activity.pictures);
      }
    });

    let num = 0;

    for (let i = pictures.length; num === 0; i--) {
      if (i % 5 === 0) {
        num = i;
      }
    }

    if (num > 5) {
      num = 5;
    }

    pictures = pictures.splice(0, num);

    const pictureGallery = (
      <div className="gallery">
        {pictures.length > 0 ? (
          pictures.map((pic, index) => (
            <div key={pic.small.path + index}>
              <img
                src={`https://api.edchild.com/${pic.small.path}`}
                alt="ffpic"
              />
            </div>
          ))
        ) : (
          <div key={preschoolFocusArea.focusArea.header}>
            <img src={preschoolFocusArea.focusArea.header} alt="ffpic" />
          </div>
        )}
      </div>
    );

    focusArea = (
      <div style={{ marginBottom: "20px" }}>
        {hasFocusArea && <h2>- Förskolans uppdrag -</h2>}
        {hasFocusArea && (
          <h3>
            En introduktion till läroplansområdet{" "}
            {preschoolFocusArea.focusArea.name}
          </h3>
        )}
        {pictureGallery}
        {hasFocusArea && (
          <div>
            <div className="page-break-inside-avoid">
              <h4>{preschoolFocusArea.focusArea.content[0].title}</h4>
              <div style={{ whiteSpace: "pre-wrap" }}>
                {preschoolFocusArea.focusArea.content[0].text}
              </div>
            </div>
            <div className="page-break-inside-avoid">
              <h4>{preschoolFocusArea.focusArea.content[1].title}</h4>
              <div style={{ whiteSpace: "pre-wrap" }}>
                {preschoolFocusArea.focusArea.content[1].text}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  let maxValuePolarDiagram = 0;
  preschoolStats &&
    preschoolStats.map((pedagogicalArea) => {
      pedagogicalArea.centralcontents.map((centralcontents) => {
        centralcontents.milestones.map((milestone) => {
          milestone.count.map((count) => {
            maxValuePolarDiagram =
              count.count > maxValuePolarDiagram
                ? count.count
                : maxValuePolarDiagram;
          });
        });
      });
    });

  const preschoolGoalsCharts = preschoolGoals && (
    <HorizontalBarChart goals={preschoolGoals} />
  );
  const redLine =
    preschoolDocumentations &&
    preschoolDocumentations.map((item) => (
      <MemoizedRedLine
        key={"docs" + item.section._id}
        section={item.section}
        documentations={item.documentations}
      />
    ));

  return (
    <div className="pageContainer">
      <div style={{ textAlign: 'center' }}>
        <img src={require('../../../../assets/solenYellowTransparent.png')} alt="logo" style={{ width: 800, height: 'auto' }} />
        <h1>
          {myPreschool && myPreschool.name} - {moment().add(-1, "month").format("MMMM")}
        </h1>
      </div>
      {/*<h3>Månadens fokusområde</h3>*/}
      {/* {focusArea} */}
      <h2 className="page-break-before">
        - Kvalitetsarbetets struktur för hela förskolan -
      </h2>
      <div>
        <h3>Vad hände under den gångna månaden?</h3>
        {trends}
        <h3>Vad hände sedan läsårets början?</h3>
        {academicYearTrends}
      </div>
      <div>
        {satisfactionPie}
      </div>
      <h2 className="page-break-before">- Var är vi? -</h2>
      <h3>
        Övergripande sammanställning sedan läsårsstarten baserat på
        dokumentationer
      </h3>
      {sectionsStats}

      <h3 className="page-break-before">
        Sammanställning per läroplansområde sedan läsårsstarten baserat på
        dokumentationer
      </h3>
      {preschoolStats &&
        myPreschool &&
        preschoolStats.map((pedagogicalArea, index) => {
          const sections = myPreschool.sections.map((item, i) => {
            item.color = colors[i];
            return item;
          });
          return (
            <MemoizedPolarGrid
              key={index + "polar" + pedagogicalArea._id}
              data={pedagogicalArea}
              sections={sections}
              max={10}
              index={index}
            />
          );
        })}
      {preschoolGoalsCharts}
      <h2 className="page-break-before">- Vart ska vi? -</h2>
      {weeklySummary}
      {/*sectionsDetailedStatsRender*/}
      <h2 className="page-break-before">- Hur blev det? -</h2>
      {redLine}
    </div>
  );
}
