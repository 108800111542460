import { SET_PEDAGOGICAL_AREAS, SET_PEDAGOGY_LOADING } from '../actions/types';

const initialState = {
  pedagogicalAreas: undefined,
  loading: true
};

const pedagogyReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PEDAGOGY_LOADING:
      return {
        ...state,
        loading: true
      };
    case SET_PEDAGOGICAL_AREAS:
      return {
        ...state,
        pedagogicalAreas: action.payload.filter(item=>item.active),
        loading: false
      };
    default:
      return state;
  }
};

export default pedagogyReducer;
