import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";

// import Footer from '../layout/Footer';
// import Showcase from '../layout/Showcase';

export const PublicRoute = ({ user, component: Component, ...rest }) => (
  <div>
    {/*<Showcase />*/}
    <Route
      {...rest}
      component={(props) =>
        user.id ? (
          user.isAdmin ? (
            <Redirect to='/admindashboard' />
          ) : (
            <Redirect to='/userdashboard' />
          )
        ) : (
          <Component {...props} />
        )
      }
    />
    {/*<Footer />*/}
  </div>
);

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(PublicRoute);
