import axios from "axios";
import { GET_ERRORS, SET_CURRENT_USER, SET_USER_EMPLOYEMENTS } from "./types";
import setAuthToken from "../../utils/setAuthToken";
import jwt_decode from "jwt-decode";

//Register User
export const registeruser = (userData, history) => (dispatch) => {
  axios
    .post("users/register", userData)
    .then((res) => history.push("/login"))
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

//Login get user token
export const loginUser = (userData) => (dispatch) => {
  axios
    .post("users/login", userData)
    .then((res) => {
      //Save to localstorage
      const { token } = res.data;

      localStorage.setItem("jwtToken", token);
      //Set token to auth header
      setAuthToken(token);
      //Decode token to get user data
      const decoded = jwt_decode(token);
      //Set current user
      dispatch({ type: SET_CURRENT_USER, payload: decoded });
      dispatch(getCurrentUserEmployements());
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response,
      })
    );
};

//Set loged in user
export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

//GET current user employements
export const getCurrentUserEmployements = () => (dispatch) => {
  axios
    .get("users/current/employements")
    .then((res) => {
      dispatch({ type: SET_USER_EMPLOYEMENTS, payload: res.data });
    })
    .catch((err) => console.log(err));
};

//Log user out
export const logoutUser = () => (dispatch) => {
  //Remove token from local storage
  localStorage.removeItem("jwtToken");
  localStorage.removeItem("currentOrganisationId");
  //Remove auth header
  setAuthToken(false);
  //Set current user to empty*
  dispatch(setCurrentUser({}));
  dispatch({ type: SET_USER_EMPLOYEMENTS, payload: null });
};
